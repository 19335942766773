import $http from './index'

export function notice(rows,page){
    return $http.get('/Inform/GetInforms',{
        params:{
            rows,
            page
        }
    })
}

//通知发送  
export function postNotice(titleText,cause,inform_time,id){
    return $http.post('/Inform/SendInformByID',{
        titleText,
        cause,
        range:'',
        inform_time,
        id
    })
}