<template>
  <div class="notice">
    <div>
      <el-button size="small" @click="sendNotice">
        <i class="el-icon-message"></i> 通知发送</el-button
      >
    </div>
    <hr />
    <el-table
      :data="tableData"
      border
      highlight-current-row
      @current-change="handleChange"
      :row-style="{ height: '50px' }"
      style="
         {
          width: 100%;
        }
      "
      max-height="770"
    >
      <el-table-column prop="index" label="编号" width="50"> </el-table-column>
      <el-table-column prop="title" label="通知标题" width="100">
      </el-table-column>
      <el-table-column prop="time" label="时间" width="130"> </el-table-column>
      <el-table-column prop="cause" label="原因" width="auto">
      </el-table-column>
      <el-table-column prop="recipient" label="接收用户" width="150">
      </el-table-column>
      <el-table-column prop="create_time" label="发布时间" width="180">
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
        background
        @current-change="handlePagination"
        v-model:currentPage="currentPage1"
        :page-size="9"
        layout="total, prev, pager, next"
        :total="totalCount"
      >
      </el-pagination>
    </div>
  </div>

  <el-dialog :title="'发布通知'" v-model="noticeModel">
    <el-form
      :model="Form"
      ref="form"
      :rules="rules"
      label-width="80px"
      :validate-on-rule-change="true"
    >
      <el-form-item label="通知类型" prop="type">
        <el-select
          v-model="Form.type"
          placeholder="请选择"
          allow-create
          filterable
        >
          <el-option
            v-for="item in typeData"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="发布时间" prop="time">
        <!-- <el-input v-model="Form.time" placeholder="如 2021-02-02"></el-input> -->
        <el-date-picker
          v-model="Form.time"
          placeholder="选择日期"
          format="YYYY 年 MM 月 DD 日"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="原因" prop="cause">
        <el-input v-model="Form.cause"></el-input>
      </el-form-item>
      <el-form-item label="接收人">
        <el-select v-model="Form.loupan" placeholder="请选择">
          <el-option
            v-for="item in louData"
            :key="item.id"
            :label="item.building_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="Form.units"
          placeholder="请选择"
          v-show="Form.loupan"
        >
          <el-option
            v-for="item in unitsData"
            :key="item.id"
            :label="item.unit_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="Form.house"
          placeholder="请选择"
          v-show="Form.units"
        >
          <el-option
            v-for="item in houseData"
            :key="item.id"
            :label="item.room_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

       <el-form-item>
					<el-input type="textarea" size="mini" v-model="Form.previewText" placeholder="预览内容" :disabled="true" :autosize="{minRows:3,maxRow:5}"></el-input>
				</el-form-item>

    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="success" style="margin-right:300px" @click="getPre">预览</el-button>
        <el-button @click="noticeModel = false">取 消</el-button>
        <el-button @click="submitNotice" type="primary">发 布</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { reactive, ref, watch } from "vue";
import { notice } from "../http/notice";
import { ElMessage } from "element-plus";
import { loupan } from "../http/information";
import { postNotice } from "../http/notice";
import { loupanUnits, loupanHouse } from "../http/information";
import { getPreview} from "../http/community";


// @ is an alias to /src
export default {
  name: "Notice",
  setup() {
    const typeData = reactive([
      { id: 1, name: "缴费通知" },
      { id: 2, name: "小区停水通知" },
      { id: 3, name: "停电通知" },
      { id: 4, name: "小区停燃气通知" },
      { id: 5, name: "小区电梯中断服务通知" },
      { id: 6, name: "小区停车场中断服务通知" },
      { id: 7, name: "小区门禁卡到期失效提醒" },
    ]);

    const louData = reactive([{ id: 0, building_name: "所有" }]);

    const unitsData = reactive([{ id: 0, unit_name: "所有" }]);
    const houseData = reactive([{ id: 0, room_name: "所有" }]);
    const noticeModel = ref(false);
    const Form = reactive({
      type: "",
      time: new Date(),
      cause: "",
      loupan: 0,
      units: 0,
      house: 0,
      previewText:''
    });
    const form = ref(null);
    const rules = reactive({
      time: [{ required: "true", message: "时间不能为空", trigger: ["blur"] }],
      cause: [{ required: "true", message: "原因不能为空", trigger: ["blur"] }],
      type: [{ required: "true", message: "类型不能为空", trigger: ["blur"] }],
    });
    const tableData = reactive([]);
    const currentPage1 = ref(1);
    const totalCount = ref(0);
    function handleChange() {}

    //级联选择器点击事件
    function handleChange() {}

    //发布请求

    function submitNotice() {
      form.value.validate((valid) => {
        if (valid) {
          let result = Form.loupan + "-" + Form.units + "-" + Form.house;

          postNotice(Form.type, Form.cause, formatDate(Form.time), result)
            .then((res) => {
              let data = res.data;
              let state = data.state;
              if (state == 0) {
                ElMessage.success(data.message);
                noticeModel.value = false;
                getNotice();
              } else {
                ElMessage.error(data.message);
              }
            })
            .catch((err) => {
              ElMessage.error("通知发送失败");
            });
        } else {
        }
      });
    }

    //日期格式转换

    function formatDate(date) {
      let year = new Date(date).getFullYear();
      let month = new Date(date).getMonth() + 1;
      let day = new Date(date).getDate();
      return year + "年" + month + "月" + day + "日";
    }

    //翻页请求
    function handlePagination(val) {
      notice(9, val)
        .then((res) => {
          let data = res.data;
          let state = data.state;
          if (state == 0) {
            tableData.length = 0;
            let resultData = data.data.rows;
            for (let i = 1; i <= resultData.length; i++) {
              let refData = {
                index: resultData[i - 1].no,
                title: resultData[i - 1].title,
                time: resultData[i - 1].inform_time,
                cause: resultData[i - 1].cause,
                recipient: resultData[i - 1].recipient,
                create_time: resultData[i - 1].create_time,
              };
              tableData.push(refData);
            }
          } else if (state == 1) {
            ElMessage.error("请求失败");
          }
        })
        .catch((err) => {
          ElMessage.error(err);
        });
    }

    // 请求楼盘

    loupan().then((res) => {
      let data = res.data;
      let state = data.state;
      if (state == 0) {
        let resultData = data.data;
        // console.log(resultData)
        for (let i = 0; i < resultData.length; i++) {
          let refData = {
            id: resultData[i].id,
            building_name: resultData[i].building_name,
          };
          louData.push(refData);
        }
      } else if (state == 1) {
      }
    });
    //初始化获取页面
    function getNotice() {
      notice(9, 1)
        .then((res) => {
          let data = res.data;
          totalCount.value = data.data.total;
          let state = data.state;
          if (state == 0) {
            tableData.length = 0;
            let resultData = data.data.rows;
            for (let i = 1; i <= resultData.length; i++) {
              let refData = {
                index: resultData[i - 1].no,
                title: resultData[i - 1].title,
                time: resultData[i - 1].inform_time,
                cause: resultData[i - 1].cause,
                recipient: resultData[i - 1].recipient,
                create_time: resultData[i - 1].create_time,
              };
              tableData.push(refData);
            }
          } else if (state == 1) {
            ElMessage.error("请求失败");
          }
        })
        .catch((err) => {
          ElMessage.error(err);
        });
    }
    //监视楼盘  获取单元
    watch(
      Form,
      (val) => {
        if (val.loupan) {
          loupanUnits(val.loupan).then((res) => {
            let data = res.data;
            let state = data.state;
            if (state == 0) {
              unitsData.length = 1;
              let result = data.data;
              for (let i = 0; i < result.length; i++) {
                let refResult = {
                  id: result[i].id,
                  unit_name: result[i].unit_name,
                };
                unitsData.push(refResult);
              }
            } else if (state == 1) {
            }
          });
        }

        if (val.units) {
          loupanHouse(val.units).then((res) => {
            let count = res.data.data.count;
            loupanHouse(val.units, count, 1).then((res) => {
              let data = res.data;
              let state = data.state;
              if (state == 0) {
                houseData.length = 1;
                let result = data.data.total;
                for (let i = 0; i < result.length; i++) {
                  let refData = {
                    id: result[i].id,
                    room_name: result[i].room_name,
                  };
                  houseData.push(refData);
                }
              }
            });
          });
        }
      },
      { immediate: true }
    );

    // 新建发送通知
    function sendNotice() {
      noticeModel.value = true;
    }
    getNotice();
       //预览
    function getPre(){
      getPreview(Form.cause).then(res=>{
     
        Form.previewText =res.data.data
      })
    }
    return {
      getPre,
      houseData,
      handleChange,
      louData,
      typeData,
      sendNotice,
      tableData,
      handleChange,
      currentPage1,
      totalCount,
      handlePagination,
      Form,
      form,
      rules,
      noticeModel,
      submitNotice,
      unitsData,
    };
  },
};
</script>

<style scoped>
.notice {
  width: 100%;
  height: 100%;
  position: relative;
}
.el-table /deep/ td {
  color: black;
}
.block {
  position: absolute;
  bottom: 0;
}
/deep/.el-table__body tr.current-row > td {
  background-color: #ffe48d;
}
</style>
